.alert {
  padding-left: 1.2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 1rem;

  &.alert-danger {
    border-left: 3px solid #ff453a; }
  &.alert-success {
    border-left: 3px solid #3fdc54; }

  .close {
    display: none; } }


.alert ul {
  padding: 0; }


.alert ul li {
  list-style: none; }
