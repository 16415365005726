.nav {
  z-index: 9999;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;

  @import '__item/nav__item';
  @import '__block/nav__block';
  @import '__menu/nav__menu';
  @import '__menu-button/nav__menu-button';
  @import '_active/nav_active'; }
