&_active {
  &:hover {
    opacity: 1; }

  @media screen and (min-width: 901px) {
    border-top: 4px solid $marine; }

  @media screen and (min-width: 1051px) {
    padding: 32px 0 12px; }

  @media screen and (max-width: 1050px) and (min-width: 901px) {
    padding: 16px 0 20px;
    &:hover {
      color: inherit; } }

  @media screen and (max-width: 900px) {
    border-left: 4px solid $marine;
    color: $marine;
    &:hover {
      color: $marine; } } }
