.form-links {
  margin: 4rem auto 0;
  text-align: center;
  font-family: "Museo Sans";
  .link {
    color: #888;
    padding: 0.2rem 0;
    margin: 1rem;
    @media screen and (min-width: 641px) {
      transition: 0.3s;
      border-bottom: 1px solid #ccc;
      &:hover {
        border-bottom: 1px solid $blue;
        color: $blue; } } } }
