ul {
  margin: 0; }

li {
  list-style-type: none; }

a {
  text-decoration: none; }

p {
  margin: 0; }

a, input, textarea, select, button {
  outline: none; }
