.block-wave-bottom {
  position: absolute;
  bottom: 0;
  z-index: 2; }

.block-wave-top {
  position: absolute;
  top: 0;
  z-index: 2;
  transform: rotate(180deg); }
