.nav__menu-button {
  .burger {
    &:nth-child(1) {
      margin-left: -10px;
      margin-top: -8px;
      transform: rotate(45deg);
      transform-origin: left top; }

    &:nth-child(2) {
      opacity: 0; }

    &:nth-child(3) {
      margin-left: -10px;
      margin-top: 8px;
      transform: rotate(-45deg);
      transform-origin: left bottom; } } }
