.input {
  background: #f4f4f4;
  border-radius: 6px;
  padding: 0.8rem 1.2rem;
  border: 1px solid #f4f4f4;
  transition: 0.3s;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    border: 1px solid #2798f0; }

  &:focus {
    background: $white;
    border: 1px solid #2798f0; } }
