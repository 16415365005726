$marine: #00b66f !default;
$marine-light: #3fe5a4 !default;

$blue: #2798f0 !default;
$blue-dark: #2261af !default;

$orange: #ff6d3c !default;

$grey: #bfcdc5 !default;
$grey-light: #f9f9f9 !default;
$grey-medium: #888 !default;
$grey-dark: #444;

$black: #000000 !default;
$black-light: #2b3238 !default;

$white: #ffffff !default;

$red: #eb4944 !default;

$border-color: #e3e3e3 !default;

$max-width: 1220px;
$margin-block: 7rem;
