.container {
  display: block;
  max-width: 1220px;
  padding: 0 50px;
  margin-left: auto;
  margin-right: auto;
  @import '_size/container_size_small';

  @media screen and (max-width: 768px) {
    padding: 0 20px; } }
