.button {
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, .3);
  color: $white;
  width: auto;
  border-radius: 6px;
  font-weight: 500;
  font-family: "Museo Sans";
  font-size: 1.2rem;
  padding: 0.7rem 2rem;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  position: relative;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: $white; }

  @media screen and (max-width: 400px) {
    padding: 0.8rem 1rem;
    min-width: 6rem; }

  @import "_bg/button_bg_white";
  @import "_bg/button_bg_non-blue";
  @import "_bg/button_bg_non-orange";
  @import "_bg/button_bg_marine";
  @import "_bg/button_bg_blue";
  @import "_size/button_size_mini";
  @import "_size/button_size_small";
  @import "_size/button_size_medium";
  @import "_outline/button_outline";
  @import "_non-active/button_non-active"; }
