.number-spinner {
  display: flex;
  .spinner-action {
    border: 1px solid $border-color;
    padding: 0 10px;
    cursor: pointer;
    user-select: none;
    &:hover {
      background-color: $grey-light; } }
  .down {
    border-radius: 5px 0 0 5px;
    border-right: none; }
  .up {
    border-radius: 0 5px 5px 0;
    border-left: none; }
  input {
    width: 40px;
    border: 1px solid $border-color;
    text-align: center; }
  span:last-child {
    margin-left: 10px; } }
