@font-face {
	font-family: 'Museo Sans';
	src: url('../fonts/MuseoSansCyrl-300.eot');
	src: url('../fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
	url('../fonts/MuseoSansCyrl-300.woff') format('woff'),
	url('../fonts/MuseoSansCyrl-300.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans';
	src: url('../fonts/MuseoSansCyrl-500.eot');
	src: url('../fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
	url('../fonts/MuseoSansCyrl-500.woff') format('woff'),
	url('../fonts/MuseoSansCyrl-500.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans';
	src: url('../fonts/MuseoSansCyrl-700.eot');
	src: url('../fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
	url('../fonts/MuseoSansCyrl-700.woff') format('woff'),
	url('../fonts/MuseoSansCyrl-700.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
