.login-social {
  display: flex;
  justify-content: space-evenly;
  .link {
    width: 22%;
    padding: 0.8rem 0;
    font-size: 1.1rem;
    border: 1px solid;
    border-radius: 6px;
    text-align: center;
    &:hover {
      color: $white; } } }

.login-google {
  color: #db4437;
  border-color: #db4437;
  &:hover {
    background: #db4437; } }

.login-vk {
  color: #5e82aa;
  border-color: #5e82aa;
  &:hover {
    background: #5e82aa; } }

.login-facebook {
  color: #2a5dc8;
  border-color: #2a5dc8;
  &:hover {
    background: #2a5dc8; } }

.login-twitter {
  color: #2ba8ff;
  border-color: #2ba8ff;
  &:hover {
    background: #2ba8ff; } }
