.details {
  width: 100%;
  margin-bottom: -0.8rem;
  &__option {
    width: 100%;
    display: flex;
    line-height: 1.4;
    margin-bottom: 0.8rem;
    font-weight: 300;
    .option {
      width: 30%;
      padding-right: 3rem; }
    .value {
      width: 60%; }
    &_total {
      margin-top: 0.5rem;
      font-weight: 700;
      .option {
        font-size: 1.2rem; }
      .value {
        font-size: 1.4rem; } }
    @media screen and (max-width: 320px) {
      flex-direction: column;
      .option {
        width: 100%;
        padding-right: 0;
        font-weight: 500; }
      .value {
        width: 100%; } } }

  &__bonus {
    font-size: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    @media screen and (max-width: 320px) {
      font-size: 0.9rem; } } }
