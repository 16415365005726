&__menu {
  display: flex;
  @media screen and (max-width: 900px) {
    display: none;
    width: 100%;
    flex-direction: column;
    overflow: auto;
    position: fixed;
    left: 0;
    top: 3rem;
    min-height: 100vh;
    background: $white;
    transition: opacity .4s,visibility .4s;
    opacity: 1;
    visibility: visible; } }
