html {
  font-size: 16px;
  background-color: #f5f5f5;
  @media screen and (max-width: 768px) {
    font-size: 14px; } }
body, input, textarea, button {
  font-family: 'Roboto', sans-serif; }

.gm-style-pbc {
  display: none !important; }

b {
  font-weight: 700; }
