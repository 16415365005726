.our-news {
  .links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    font-size: 2.5em;
    a {
      color: #acacac; }
    a:hover {
      color: #23527c; } } }
