.login-form {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  padding: 2rem;
  border-radius: 12px;
  background: $white;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: "Museo Sans";

  .title {
    margin-top: 0; }

  .button {
    width: 100%;
    box-sizing: border-box; }

  .description {
    font-size: 0.8rem;
    color: #888;
    line-height: 1.8;
    font-weight: 300; } }
.min-width-600 {
  min-width: 600px;
  @media screen and (max-width: 768px) {
    min-width: auto; } }
