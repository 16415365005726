&_link {
  font-weight: 500;
  position: relative;
  text-decoration: none;
  transition: 0.3s;
  color: #fff;
  box-sizing: content-box;
  padding: 36px 0 12px;

  &:hover {
    opacity: 0.7; }

  @media screen and (max-width: 1050px) and (min-width: 901px) {
    font-size: 0.9rem;
    padding: 20px 0 20px; }

  @media screen and (max-width: 1050px) {
    color: $grey-dark;
    &:hover {
      color: $blue; } }

  @media screen and (max-width: 900px) {
    font-size: 1rem;
    padding: 0.2rem 0 0.2rem;
    margin: 1.5rem 1rem;
    justify-content: center; } }
