&_outline {
  &:after {
    content: " ";
    position: absolute;
    width: 132%;
    height: 172%;
    border-radius: 64px;
    border: 1px solid rgba(255, 255, 255, .3);
    left: -17%;
    top: -36%; } }


