.checkbox-visible {
  display: flex;
  align-items: center;
  margin-right: 5px;
  label {
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    vertical-align: middle; }
  input[type=checkbox] {
    position: static;
    opacity: 1;
    margin: 0 10px 0 0;
    vertical-align: middle; } }
