.link {
  transition: 0.3s;
  font-weight: 400;
  color: $blue;

  @import '_dashed/link_dashed';
  @import '_size/link_size_medium';
  @import '_weight/link_weight_light';
  @import '_weight/link_weight_medium';
  @import '_weight/link_weight_bold';
  @import '_padding/link_padding'; }
