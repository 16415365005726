.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 6rem 0 2rem;
  font-size: 0.9rem;
  color: #888;
  .copy {
    margin: 1.5rem 2rem 1.5rem 0;
    line-height: 1.2;
    .link {
      color: #888;
      padding: 0.2rem 0;
      margin: 0.4rem;
      @media screen and (min-width: 641px) {
        transition: 0.3s;
        border-bottom: 1px solid #ccc;
        &:hover {
          border-bottom: 1px solid $blue;
          color: $blue; } } } } }
