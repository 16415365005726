.form-group {
  width: 100%;
  margin-bottom: 1.4rem;
  &:last-child {
    margin-bottom: 0; }
  .title {
    font-size: 1.2rem;
    margin: 0 0 0.6rem; }
  .checkbox {
    margin-top: 0.8rem;
    font-weight: 300; }
  .help-block {
    font-weight: 300;
    margin-top: 0.5rem;
    display: block;
    font-size: 13px; }
  &.has-error {
    .input {
      border: 1px solid #cc0000; }
    .help-block {
      color: #cc0000; } } }
