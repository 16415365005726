.register-form {
  display: flex;
  justify-content: space-between;
  max-width: 900px;
  padding: 2rem;
  border-radius: 12px;
  background: $white;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: "Museo Sans";

  .column {
    width: 44%;
    &:last-child {
      margin-top: 2rem; }
    @media screen and (min-width: 641px) {
      &:first-child {
        padding-right: 6%;
        border-right: 1px solid $border-color; } } }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    justify-content: flex-start;
    .column {
      width: 100%;
      &:last-child {
        padding-top: 3rem;
        border-top: 1px solid $border-color; } } }

  .title {
    margin-top: 0; }

  .button {
    width: 100%;
    box-sizing: border-box; }

  .description {
    font-size: 0.8rem;
    color: #888;
    line-height: 1.8;
    font-weight: 300; } }
