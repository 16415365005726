.input-with-button-inline {
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: 400px) {
    flex-flow: column; }
  .input-help-block-wrapper {
    margin-right: 15px;
    width: 100%;
    @media screen and (max-width: 400px) {
      margin-right: 0;
      margin-bottom: 15px;
      width: 100%; } }
  .button {
    width: 40%;
    padding: 0.7em 1em;
    @media screen and (max-width: 400px) {
      width: 100%;
      padding: 0.8rem 1rem; } }
  .input {
    padding: 1rem 1.2rem; } }
