.title {
  font-size: 3rem;
  line-height: 1;
  font-family: 'Museo Sans';
  font-weight: 700;
  margin: 1rem 0;

  @media screen and (max-width: 768px) {
    font-size: 2.8rem; }

  @import '_size/title_size_small';
  @import '_size/title_size_medium';
  @import '_size/title_size_large';
  @import '_centered/title_centered';
  @import '_padding/title_padding'; }
