/* vendor */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&amp;subset=cyrillic");
/* global */
html {
  font-size: 16px;
  background-color: #f5f5f5;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

body, input, textarea, button {
  font-family: 'Roboto', sans-serif;
}

.gm-style-pbc {
  display: none !important;
}

b {
  font-weight: 700;
}

ul {
  margin: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

a, input, textarea, select, button {
  outline: none;
}

/* blocks */
.button {
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, 0.3);
  color: #ffffff;
  width: auto;
  border-radius: 6px;
  font-weight: 500;
  font-family: "Museo Sans";
  font-size: 1.2rem;
  padding: 0.7rem 2rem;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}

.button:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

@media screen and (max-width: 400px) {
  .button {
    padding: 0.8rem 1rem;
    min-width: 6rem;
  }
}

.button_bg_white {
  background: #ffffff;
}

.button_bg_non-blue {
  border: 1px solid #2798f0;
  color: #2798f0;
  background: none;
}

.button_bg_non-blue:hover {
  background: #2798f0;
  color: #ffffff;
}

.button_bg_non-orange {
  border: 1px solid #ff6d3c;
  color: #ff6d3c;
  background: none;
}

.button_bg_non-orange:hover {
  background: #ff6d3c;
  color: #ffffff;
}

.button_bg_marine {
  background: #00b66f;
  border-color: #00b66f;
}

.button_bg_marine:hover {
  background: #3fe5a4;
  border-color: #3fe5a4;
}

.button_bg_blue {
  background: #2798f0;
  border-color: #2798f0;
  color: #fff;
}

.button_bg_blue:hover {
  background: #00b66f;
  border-color: #00b66f;
}

.button_size_mini {
  padding: 0.8rem 2rem;
  font-size: 1rem;
  min-width: 0;
}

.button_size_small {
  padding: 0.5rem 1.6rem;
  font-size: 0.8rem;
  font-weight: 700;
  min-width: 0;
}

.button_size_medium {
  padding: 0.6rem 2rem;
  font-size: 1rem;
  min-width: 0;
}

.button_outline:after {
  content: " ";
  position: absolute;
  width: 132%;
  height: 172%;
  border-radius: 64px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  left: -17%;
  top: -36%;
}

.button_non-active {
  opacity: 0.4;
  cursor: default;
}

.container {
  display: block;
  max-width: 1220px;
  padding: 0 50px;
  margin-left: auto;
  margin-right: auto;
}

.container_size_small {
  max-width: 1020px;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
}

.link {
  transition: 0.3s;
  font-weight: 400;
  color: #2798f0;
}

.link_dashed {
  border-bottom: 1px dashed;
}

@media screen and (max-width: 320px) {
  .link_dashed {
    border: none;
  }
}

.link_size_medium {
  font-size: 1.2rem;
}

.link_weight_light {
  font-weight: 300;
}

.link_weight_medium {
  font-weight: 500;
}

.link_weight_bold {
  font-weight: 700;
}

.link_padding {
  display: inline-block;
  padding: 1rem 2rem 1rem 0;
}

.nav {
  z-index: 9999;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.nav__item {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin: 0 1.2rem;
}

.nav__item_link {
  font-weight: 500;
  position: relative;
  text-decoration: none;
  transition: 0.3s;
  color: #fff;
  box-sizing: content-box;
  padding: 36px 0 12px;
}

.nav__item_link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1050px) and (min-width: 901px) {
  .nav__item_link {
    font-size: 0.9rem;
    padding: 20px 0 20px;
  }
}

@media screen and (max-width: 1050px) {
  .nav__item_link {
    color: #444;
  }
  .nav__item_link:hover {
    color: #2798f0;
  }
}

@media screen and (max-width: 900px) {
  .nav__item_link {
    font-size: 1rem;
    padding: 0.2rem 0 0.2rem;
    margin: 1.5rem 1rem;
    justify-content: center;
  }
}

.nav__item_active:hover {
  opacity: 1;
}

@media screen and (min-width: 901px) {
  .nav__item_active {
    border-top: 4px solid #00b66f;
  }
}

@media screen and (min-width: 1051px) {
  .nav__item_active {
    padding: 32px 0 12px;
  }
}

@media screen and (max-width: 1050px) and (min-width: 901px) {
  .nav__item_active {
    padding: 16px 0 20px;
  }
  .nav__item_active:hover {
    color: inherit;
  }
}

@media screen and (max-width: 900px) {
  .nav__item_active {
    border-left: 4px solid #00b66f;
    color: #00b66f;
  }
  .nav__item_active:hover {
    color: #00b66f;
  }
}

.nav__block {
  display: flex;
}

.nav__menu {
  display: flex;
}

@media screen and (max-width: 900px) {
  .nav__menu {
    display: none;
    width: 100%;
    flex-direction: column;
    overflow: auto;
    position: fixed;
    left: 0;
    top: 3rem;
    min-height: 100vh;
    background: #ffffff;
    transition: opacity .4s,visibility .4s;
    opacity: 1;
    visibility: visible;
  }
}

.nav__menu-button {
  display: none;
  height: 3rem;
  width: 4rem;
  position: relative;
}

@media screen and (max-width: 900px) {
  .nav__menu-button {
    display: block;
  }
}

.nav_active .nav__menu {
  display: block;
  padding-top: 2rem;
}

.nav_active .nav__menu-button .burger:nth-child(1) {
  margin-left: -10px;
  margin-top: -8px;
  transform: rotate(45deg);
  transform-origin: left top;
}

.nav_active .nav__menu-button .burger:nth-child(2) {
  opacity: 0;
}

.nav_active .nav__menu-button .burger:nth-child(3) {
  margin-left: -10px;
  margin-top: 8px;
  transform: rotate(-45deg);
  transform-origin: left bottom;
}

.body_clipped {
  overflow: hidden !important;
}

.header-block {
  background: #2261af url("/static/img/bg-main.jpg") no-repeat;
  background-size: cover;
  position: relative;
}

.header-block__content {
  padding: 12rem 0;
  text-align: center;
}

.header-block__content .img {
  margin: 2rem;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 6rem 0 2rem;
  font-size: 0.9rem;
  color: #888;
}

.footer .copy {
  margin: 1.5rem 2rem 1.5rem 0;
  line-height: 1.2;
}

.footer .copy .link {
  color: #888;
  padding: 0.2rem 0;
  margin: 0.4rem;
}

@media screen and (min-width: 641px) {
  .footer .copy .link {
    transition: 0.3s;
    border-bottom: 1px solid #ccc;
  }
  .footer .copy .link:hover {
    border-bottom: 1px solid #2798f0;
    color: #2798f0;
  }
}

.title {
  font-size: 3rem;
  line-height: 1;
  font-family: 'Museo Sans';
  font-weight: 700;
  margin: 1rem 0;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 2.8rem;
  }
}

.title_size_small {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2;
}

.title_size_medium {
  font-size: 1.5rem;
}

@media screen and (max-width: 1050px) {
  .title_size_medium {
    font-size: 1.3rem;
  }
}

.title_size_large {
  font-size: 3rem;
}

@media screen and (max-width: 1050px) {
  .title_size_large {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .title_size_large {
    font-size: 2.2rem;
  }
}

.title_centered {
  text-align: center;
}

.title_padding {
  padding: 4rem 0 2rem;
}

.subtitle {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 300;
}

.subtitle_centered {
  text-align: center;
}

.subtitle_size_small {
  font-size: 1rem;
}

.subtitle_size_medium {
  font-size: 1.5rem;
}

.subtitle_padding {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.icon_size_large {
  font-size: 3rem;
}

.icon_size_medium {
  font-size: 2rem;
}

.icon_padding_left {
  padding-left: 2rem;
}

.icon_padding_right {
  padding-right: 2rem;
}

.icon_padding_min-left {
  padding-left: 1rem;
}

.icon_padding_min-right {
  padding-right: 1rem;
}

.icon_color_blue {
  color: #2798f0;
}

.icon_color_white {
  color: #ffffff;
}

.button-container {
  display: flex;
  justify-content: space-between;
  padding: 3.5rem 0;
}

.hr {
  border: none;
  height: 1px;
}

.hr_standart {
  color: #e3e3e3;
  background-color: #e3e3e3;
  margin: 1.4rem 0;
}

.hr_breaker {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  text-align: center;
  height: 1.5em;
  opacity: .7;
  background: none;
}

.hr_breaker:before {
  content: '';
  background: #e3e3e3;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr_breaker:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 .5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #ffffff;
}

.input {
  background: #f4f4f4;
  border-radius: 6px;
  padding: 0.8rem 1.2rem;
  border: 1px solid #f4f4f4;
  transition: 0.3s;
  width: 100%;
  box-sizing: border-box;
}

.input:hover {
  border: 1px solid #2798f0;
}

.input:focus {
  background: #ffffff;
  border: 1px solid #2798f0;
}

.textarea_main-form {
  border-radius: 6px;
  width: 100%;
  height: 60%;
  padding: 0.8rem 1.2rem;
  line-height: 1.4;
  border: none;
}

.text_weight_light {
  font-weight: 300;
}

.text_weight_medium {
  font-weight: 500;
}

.text_padding_left {
  margin-left: 0.6rem;
}

.text_color_white {
  color: #ffffff;
}

.text_color_marine {
  color: #00b66f;
}

.text_color_marine-light {
  color: #3fe5a4;
}

.text_color_blue {
  color: #2798f0;
}

.text_color_orange {
  color: #ff6d3c;
}

.container-empty {
  height: 7rem;
}

@media screen and (max-width: 1050px) {
  .container-empty {
    height: 3.6rem;
  }
}

@media screen and (max-width: 768px) {
  .container-empty {
    height: 3rem;
  }
}

.bg {
  width: 100%;
  position: relative;
  padding: 3rem 0 300px;
}

.bg_color_marine {
  background: linear-gradient(45deg, #13cf97, #25b8aa);
}

.bg_color_blue {
  background: linear-gradient(45deg, #0c3a83, #1e7cd7);
}

.bg_color_orange {
  background: linear-gradient(45deg, #ff693d, #fe962f);
}

.bg_lite-grey {
  background-color: #f9f9f9;
}

.block-wave-bottom {
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.block-wave-top {
  position: absolute;
  top: 0;
  z-index: 2;
  transform: rotate(180deg);
}

.login-header {
  text-align: center;
}

.login-header .title {
  color: #ffffff;
  font-weight: 500;
}

.login-header .subtitle {
  margin-top: 1rem;
}

.login-header .logo {
  margin-bottom: 3rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  padding: 2rem;
  border-radius: 12px;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: "Museo Sans";
}

.login-form .title {
  margin-top: 0;
}

.login-form .button {
  width: 100%;
  box-sizing: border-box;
}

.login-form .description {
  font-size: 0.8rem;
  color: #888;
  line-height: 1.8;
  font-weight: 300;
}

.min-width-600 {
  min-width: 600px;
}

@media screen and (max-width: 768px) {
  .min-width-600 {
    min-width: auto;
  }
}

.login-content {
  position: relative;
  z-index: 100;
  margin-top: -16rem;
}

@media screen and (max-width: 768px) {
  .login-content {
    margin-top: -20rem;
  }
}

.login-social {
  display: flex;
  justify-content: space-evenly;
}

.login-social .link {
  width: 22%;
  padding: 0.8rem 0;
  font-size: 1.1rem;
  border: 1px solid;
  border-radius: 6px;
  text-align: center;
}

.login-social .link:hover {
  color: #ffffff;
}

.login-google {
  color: #db4437;
  border-color: #db4437;
}

.login-google:hover {
  background: #db4437;
}

.login-vk {
  color: #5e82aa;
  border-color: #5e82aa;
}

.login-vk:hover {
  background: #5e82aa;
}

.login-facebook {
  color: #2a5dc8;
  border-color: #2a5dc8;
}

.login-facebook:hover {
  background: #2a5dc8;
}

.login-twitter {
  color: #2ba8ff;
  border-color: #2ba8ff;
}

.login-twitter:hover {
  background: #2ba8ff;
}

.register-form {
  display: flex;
  justify-content: space-between;
  max-width: 900px;
  padding: 2rem;
  border-radius: 12px;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: "Museo Sans";
}

.register-form .column {
  width: 44%;
}

.register-form .column:last-child {
  margin-top: 2rem;
}

@media screen and (min-width: 641px) {
  .register-form .column:first-child {
    padding-right: 6%;
    border-right: 1px solid #e3e3e3;
  }
}

@media screen and (max-width: 640px) {
  .register-form {
    flex-direction: column;
    justify-content: flex-start;
  }
  .register-form .column {
    width: 100%;
  }
  .register-form .column:last-child {
    padding-top: 3rem;
    border-top: 1px solid #e3e3e3;
  }
}

.register-form .title {
  margin-top: 0;
}

.register-form .button {
  width: 100%;
  box-sizing: border-box;
}

.register-form .description {
  font-size: 0.8rem;
  color: #888;
  line-height: 1.8;
  font-weight: 300;
}

.form-group {
  width: 100%;
  margin-bottom: 1.4rem;
}

.form-group:last-child {
  margin-bottom: 0;
}

.form-group .title {
  font-size: 1.2rem;
  margin: 0 0 0.6rem;
}

.form-group .checkbox {
  margin-top: 0.8rem;
  font-weight: 300;
}

.form-group .help-block {
  font-weight: 300;
  margin-top: 0.5rem;
  display: block;
  font-size: 13px;
}

.form-group.has-error .input {
  border: 1px solid #cc0000;
}

.form-group.has-error .help-block {
  color: #cc0000;
}

.form-links {
  margin: 4rem auto 0;
  text-align: center;
  font-family: "Museo Sans";
}

.form-links .link {
  color: #888;
  padding: 0.2rem 0;
  margin: 1rem;
}

@media screen and (min-width: 641px) {
  .form-links .link {
    transition: 0.3s;
    border-bottom: 1px solid #ccc;
  }
  .form-links .link:hover {
    border-bottom: 1px solid #2798f0;
    color: #2798f0;
  }
}

.details {
  width: 100%;
  margin-bottom: -0.8rem;
}

.details__option {
  width: 100%;
  display: flex;
  line-height: 1.4;
  margin-bottom: 0.8rem;
  font-weight: 300;
}

.details__option .option {
  width: 30%;
  padding-right: 3rem;
}

.details__option .value {
  width: 60%;
}

.details__option_total {
  margin-top: 0.5rem;
  font-weight: 700;
}

.details__option_total .option {
  font-size: 1.2rem;
}

.details__option_total .value {
  font-size: 1.4rem;
}

@media screen and (max-width: 320px) {
  .details__option {
    flex-direction: column;
  }
  .details__option .option {
    width: 100%;
    padding-right: 0;
    font-weight: 500;
  }
  .details__option .value {
    width: 100%;
  }
}

.details__bonus {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 1rem;
}

@media screen and (max-width: 320px) {
  .details__bonus {
    font-size: 0.9rem;
  }
}

.alert {
  padding-left: 1.2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 1rem;
}

.alert.alert-danger {
  border-left: 3px solid #ff453a;
}

.alert.alert-success {
  border-left: 3px solid #3fdc54;
}

.alert .close {
  display: none;
}

.alert ul {
  padding: 0;
}

.alert ul li {
  list-style: none;
}

.input-with-button-inline {
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 400px) {
  .input-with-button-inline {
    flex-flow: column;
  }
}

.input-with-button-inline .input-help-block-wrapper {
  margin-right: 15px;
  width: 100%;
}

@media screen and (max-width: 400px) {
  .input-with-button-inline .input-help-block-wrapper {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
  }
}

.input-with-button-inline .button {
  width: 40%;
  padding: 0.7em 1em;
}

@media screen and (max-width: 400px) {
  .input-with-button-inline .button {
    width: 100%;
    padding: 0.8rem 1rem;
  }
}

.input-with-button-inline .input {
  padding: 1rem 1.2rem;
}

.our-news .links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  font-size: 2.5em;
}

.our-news .links a {
  color: #acacac;
}

.our-news .links a:hover {
  color: #23527c;
}

.number-spinner {
  display: flex;
}

.number-spinner .spinner-action {
  border: 1px solid #e3e3e3;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
}

.number-spinner .spinner-action:hover {
  background-color: #f9f9f9;
}

.number-spinner .down {
  border-radius: 5px 0 0 5px;
  border-right: none;
}

.number-spinner .up {
  border-radius: 0 5px 5px 0;
  border-left: none;
}

.number-spinner input {
  width: 40px;
  border: 1px solid #e3e3e3;
  text-align: center;
}

.number-spinner span:last-child {
  margin-left: 10px;
}

.checkbox-visible {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.checkbox-visible label {
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  vertical-align: middle;
}

.checkbox-visible input[type=checkbox] {
  position: static;
  opacity: 1;
  margin: 0 10px 0 0;
  vertical-align: middle;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}
